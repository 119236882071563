import { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAppState } from "../../redux/features/appStateSlice";
import { Box } from "@mui/material";
import AccountMenu from "../common/AccountMenu";

type Props = {
  state?: string,
  children: ReactNode;
};

const PageWrapper = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.state) {
      dispatch(setAppState(props.state));
    }
  }, [dispatch, props]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" p={0} marginBottom={3}>
        <AccountMenu mobile={false}/>
      </Box>
      {props.children}
    </>
  );
};

export default PageWrapper;